<template lang="pug">
  div
    slot(name="activator")
      v-btn(small color='primary'
        @click="show"
        :disabled="disabled"
        outlined
      ) Sync
    confirmation-dialog(
      v-model="showing"
      title="Sync"
      okText="Ok"
      failText="Cancel"
      descr="Are you sure you want to sent TOP CBR import request?"
      @okBtnClicked="sync"
      @failBtnClicked="hide"
      @click:outside="hide"
    )
</template>


<script>
  import errorsMixin from '@/mixins/errors.mixin'

  export default {
    inject: ['svc'],
    mixins: [ errorsMixin ],
    props: {
      disabled: Boolean,
      activeItems: Array,
    },
    data: () => ({
      showing: false
    }),
    methods: {
      hide() {
        this.showing = false
      },
      show() {
        this.showing = true
      },
      async sync() {
        try {
          const ids = this.activeItems.map(item => item.ID);
          await this.svc().sync(ids)
          this.$notify({text: 'TOP CBR import request sended', type: 'success'})
          this.hide()
        } catch(err) {
          this.processError(err)
        }
      }
    },
    components: {
      confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
    }
  }
</script>